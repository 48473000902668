import { SubscriptionInterval, SubscriptionLevel, SubscriptionPointAmount } from '../../../enums'

export function getPoints(subLevel: SubscriptionLevel, interval: SubscriptionInterval): number {
  switch (true) {
    case interval === SubscriptionInterval.Weekly && subLevel === SubscriptionLevel.Plus:
      return SubscriptionPointAmount.PlusWeekly
    case interval === SubscriptionInterval.Weekly && subLevel === SubscriptionLevel.Infinite:
      return SubscriptionPointAmount.InfiniteWeekly
    case subLevel === SubscriptionLevel.Plus:
      return SubscriptionPointAmount.Plus
    case subLevel === SubscriptionLevel.Infinite:
      return SubscriptionPointAmount.Infinite
    default:
      return SubscriptionPointAmount.Plus
  }
}

export const calculateAmountPerWeek = (amount: number, interval: SubscriptionInterval) => {
  let result = amount
  switch (interval) {
    case SubscriptionInterval.Monthly:
      result = amount / 4
      break
    case SubscriptionInterval.SixMonths:
      result = amount / 6 / 4
      break
    default:
      break
  }
  return Math.trunc(Math.floor(result * 100) / 100)
}

export const getDeviceName = () => {
  const { userAgent } = navigator
  if (/android/i.test(userAgent)) {
    return 'Android Device'
  }
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS Device'
  }
  if (/Macintosh/.test(userAgent)) {
    return 'Mac OS Device'
  }
  if (/Windows/.test(userAgent)) {
    return 'Windows Device'
  }
  return 'Unknown Device'
}
